// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Section_container__3md8M {\n  width: 100%;\n  max-width: 775px;\n  background: #fff;\n  border-radius: 6px;\n  margin: 10px auto;\n  padding: 74px 82px 53px;\n  border: solid 1px #2c2c2c;\n  box-sizing: border-box;\n}\n.Section_container__3md8M h2 {\n  font-weight: 600;\n  font-size: 28px;\n  line-height: 33px;\n  color: #2c2c2c;\n}\n.Section_container__3md8M article {\n  font-weight: 500;\n  font-size: 16px;\n  line-height: 19px;\n  color: #000000;\n  margin-top: 19px;\n}", "",{"version":3,"sources":["webpack://src/components/Section/Section.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,gBAAA;EACA,gBAAA;EACA,kBAAA;EACA,iBAAA;EACA,uBAAA;EACA,yBAAA;EACA,sBAAA;AACF;AACE;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AACJ;AAEE;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,gBAAA;AAAJ","sourcesContent":[".container {\n  width: 100%;\n  max-width: 775px;\n  background: #fff;\n  border-radius: 6px;\n  margin: 10px auto;\n  padding: 74px 82px 53px;\n  border: solid 1px #2c2c2c;\n  box-sizing: border-box;\n\n  h2 {\n    font-weight: 600;\n    font-size: 28px;\n    line-height: 33px;\n    color: #2c2c2c;\n  }\n\n  article {\n    font-weight: 500;\n    font-size: 16px;\n    line-height: 19px;\n    color: #000000;\n    margin-top: 19px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Section_container__3md8M"
};
export default ___CSS_LOADER_EXPORT___;
