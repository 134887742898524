// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Button_container__2B8O8 {\n  height: 60px;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-pack: center;\n      justify-content: center;\n  -ms-flex-align: center;\n      align-items: center;\n  border-radius: 5px;\n  font-weight: 600;\n  font-size: 15px;\n  line-height: 18px;\n  border: none;\n  outline: none;\n  padding: 0px 23px;\n  grid-gap: 11px;\n  gap: 11px;\n  cursor: pointer;\n}\n.Button_container__2B8O8:disabled {\n  opacity: 0.7;\n  cursor: not-allowed;\n}\n.Button_container__2B8O8.Button_pink__1brNd {\n  background: #f1d1e5;\n  color: #2c2c2c;\n}\n.Button_container__2B8O8.Button_black__2zUj0 {\n  background: #2c2c2c;\n  color: #f1d1e5;\n}\n.Button_container__2B8O8 img {\n  width: 25px;\n}", "",{"version":3,"sources":["webpack://src/components/Button/Button.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,oBAAA;EAAA,aAAA;EACA,qBAAA;MAAA,uBAAA;EACA,sBAAA;MAAA,mBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,YAAA;EACA,aAAA;EACA,iBAAA;EACA,cAAA;EAAA,SAAA;EACA,eAAA;AACF;AACE;EACE,YAAA;EACA,mBAAA;AACJ;AAEE;EACE,mBAAA;EACA,cAAA;AAAJ;AAGE;EACE,mBAAA;EACA,cAAA;AADJ;AAIE;EACE,WAAA;AAFJ","sourcesContent":[".container {\n  height: 60px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border-radius: 5px;\n  font-weight: 600;\n  font-size: 15px;\n  line-height: 18px;\n  border: none;\n  outline: none;\n  padding: 0px 23px;\n  gap: 11px;\n  cursor: pointer;\n\n  &:disabled {\n    opacity: 0.7;\n    cursor: not-allowed;\n  }\n\n  &.pink {\n    background: #f1d1e5;\n    color: #2c2c2c;\n  }\n\n  &.black {\n    background: #2c2c2c;\n    color: #f1d1e5;\n  }\n\n  img {\n    width: 25px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Button_container__2B8O8",
	"pink": "Button_pink__1brNd",
	"black": "Button_black__2zUj0"
};
export default ___CSS_LOADER_EXPORT___;
