import RightArrow from '../../assets/right-arrow.png';
import Discord from '../../assets/discord.png';
import Egg from '../../assets/egg.png';
import Grain from '../../assets/grain.png';

const Icons = {
  RightArrow,
  Discord,
  Egg,
  Grain,
};

export default Icons;
